body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background: linear-gradient(90deg, #292E49, #536976);
  background-size: 1000vw auto;
  
  -webkit-animation: rainbow 25s ease infinite;
  -z-animation: rainbow 25s ease infinite;
  -o-animation: rainbow 25s ease infinite;
    animation: rainbow 25s ease infinite;
}

@-webkit-keyframes rainbow {
  0%{background-position:0% 0%}
  50%{background-position:100% 0%}
  100%{background-position:0% 0%}
}
@-moz-keyframes rainbow {
  0%{background-position:0% 0%}
  50%{background-position:100% 0%}
  100%{background-position:0% 0%}
}
@-o-keyframes rainbow {
  0%{background-position:0% 0%}
  50%{background-position:100% 0%}
  100%{background-position:0% 0%}
}
@keyframes rainbow { 
  0%{background-position:0% 0%}
  50%{background-position:100% 0%}
  100%{background-position:0% 0%}
}

::-webkit-scrollbar {
  display: none; 
}