.portfolio_page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .content_grid {
    display: flex;
    flex: auto;
    flex-direction: column;
    padding: 4rem 3rem 1rem;
    text-align: left;

    h1 {
      margin: 1rem 0;
      font-size: 3rem;
    }

    .portfolio_wrapper {
      color: #ffffff;
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      flex-wrap: wrap;

      .portfolio_item {
        margin: 1rem;
        width: 18rem;
        height: 18rem;
        background-color: #fff;
        color: #777;
        border-radius: 3px;
        transition-duration: 0.3s;
        position: relative;

        &:hover {
          transform: translateY(-0.5rem);
          box-shadow: 0px 0.5rem 0.5rem #222;

          .portfolio_item_mask {
            display: block;
            background: rgba(0, 0, 0, 0.5);
            width: 100%;
            height: 100%;
          }

          .portfolio_item_links {
            display: block;
          }
        }

        .portfolio_item_cover {
          height: 50%;
          width: 100%;
        }

        .portfolio_item_mask {
          display: none;
          position: absolute;
          top: 0;
          left: 0;
        }

        .portfolio_item_content {
          height: 50%;
          box-sizing: border-box;
          padding: 1rem;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
        }

        .portfolio_item_title {
          font-size: 0.9rem;
        }

        .portfolio_item_desc {
          font-size: 0.8rem;
          font-weight: bold;
        }

        .portfolio_item_links {
          display: none;

          & > a {
            color: #eee;
            background: rgba(0, 0, 0, 0.1);
            text-decoration: none;
            border-radius: 4px;
            padding: 1rem 1.5rem;
            border: 2px solid #eee;
            margin: auto;
            top: 50%;
            left: 50%;
            position: absolute;
            transform: translate(-50%, -50%);
            white-space: nowrap;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 991px) {
}

@media screen and (max-width: 767px) {
}

@media screen and (max-width: 575px) {
}
